import React from 'react';

import { WorkflowStepsProps, StepProps } from 'ui-kit/workflow-steps/workflow-steps.props';

import './workflow-steps.style.scss';

const Step = ({ isActive }: StepProps) => {
    return <div className={`workflow-step ${isActive ? 'active' : ''}`}></div>;
};

const WorkflowSteps = ({ activeStep, activeStepName, id, numberOfSteps }: WorkflowStepsProps) => {
    const getSteps = () => {
        let steps = [];
        for (let i = 1; i <= numberOfSteps; i++) {
            steps.push(<Step key={`workflow-${id}-step=${i}`} isActive={activeStep === i} />);
        }
        return steps;
    };
    return (
        <div className="workflow-steps" data-ga-form-stepname={`${activeStep}-${activeStepName}`}>
            {getSteps()}
        </div>
    );
};

export default WorkflowSteps;
