import { FormikHelpers } from 'formik';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AuthMobileNav from 'components/auth-mobile-nav';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import ProfileSetupForm from 'components/profile-setup-form/profile-setup-form.component';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

import { accountRegisterProfileRoutine } from 'state/account/account.routines';
// State
import { accountRegistrationSelector } from 'state/account/account.selectors';
import { PatientRegistrationErrorPayload } from 'state/account/account.services';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { ProfileFormValues } from 'types/profile';

import { getPhoneNumber } from 'util/globalVariables';

const GetStarted = ({ data }: { data: GatsbyTypes.ProfileSetupDataQuery }): ReactElement => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const accountRegistration = useSelector(accountRegistrationSelector);

    const showDefaultErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('registration.errors.modals.default.body')}
                        note={t('registration.errors.modals.default.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ProfileSetupError',
                        label: t('registration.errors.modals.default.button'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger headerText={t('registration.errors.modals.default.title')} icon="alert" />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const showEmailAlreadyOnFileErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('registration.errors.modals.emailAlreadyOnFile.body')}
                        note={t('registration.errors.modals.emailAlreadyOnFile.note', {
                            phoneNumber: getPhoneNumber({ isEnd: true })
                        })}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ProfileSetupError',
                        label: t('registration.errors.modals.emailAlreadyOnFile.button'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('registration.errors.modals.emailAlreadyOnFile.title')}
                        icon="alert"
                    />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const handleSubmitProfile = (
        profile: Partial<ProfileFormValues>,
        helpers: FormikHelpers<Partial<ProfileFormValues>>
    ) => {
        dispatch(
            accountRegisterProfileRoutine.trigger({
                formValues: profile,
                formHelpers: helpers,
                onFailure: (error: PatientRegistrationErrorPayload) => {
                    if (error?.SystemMessage === 'Username is invalid') {
                        showEmailAlreadyOnFileErrorModal();
                    } else {
                        showDefaultErrorModal();
                    }
                }
            })
        );
    };

    if (!accountRegistration.patientRegistration) {
        navigate('/get-started');
    }

    return accountRegistration.patientRegistration ? (
        <WorkflowLayout
            backgroundImage={data.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('profile.eyebrowText') }}
            anonymousOnly={true}
        >
            <WorkflowLayoutFormWrapper
                eyebrowText={t('profile.eyebrowText')}
                title={t('registration.finalizeTitle')}
                nav={<AuthMobileNav hideBackButton />}
            >
                <ProfileSetupForm onSubmit={handleSubmitProfile} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    ) : (
        <></>
    );
};

export default GetStarted;

export const query = graphql`
    query ProfileSetupData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        blueSkyBackground: file(relativePath: { eq: "assets/images/feather-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
