import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { Form, Formik } from 'formik';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { PROFILE_SCHEMA } from 'schema/profile.schema';

import Button from 'ui-kit/button/button';
import Text from 'ui-kit/text/text';
import WorkflowSteps from 'ui-kit/workflow-steps/workflow-steps';

import { ProfileSetupFormProps } from './profile-setup-form.props';
import PhoneNumberText from 'ui-kit/phone-number-text/phone-number-text';
import PasswordText from 'ui-kit/password-text/password-text';
import PasswordRules from 'components/password-rules/password-rules.component';
import { TrackInputError } from 'util/google_optimize/optimize_helper';
import { useGlobalLink } from 'hooks/useGlobalLink';

const ProfileSetupForm = ({ onSubmit, style }: ProfileSetupFormProps) => {
    const { t } = useTranslation();
    const formName = 'Registration - Login';
    let currentError = '';

    function showError(status: string): string {
        if (status) {
            if (status !== currentError) {
                currentError = status;
                TrackInputError(null, 'Form Submit', status, formName);
            }
        } else {
            currentError = '';
        }
        return status;
    }

    const parsePhoneError = (errors: Record<string, any>) => {
        const phoneErrorMessages: Record<string, any> = {
            phoneNumber: t('registration.errors.phoneNumber'),
            phoneNumberInvalid: t('registration.errors.phoneNumberInvalid')
        };

        return errors?.phoneNumber ? phoneErrorMessages[errors.phoneNumber] : undefined;
    };

    const globalLink = useGlobalLink();
    useEffect(() => {
        globalLink.setFormName(formName);
        globalLink.setFlowName('Signup');
        globalLink.setStepName(t('registration.finalizeTitle'));
    }, [globalLink, t]);

    const parsePwError = (errors: Record<string, any>, values: Record<string, any>) => {
        const { password, passwordConfirm } = values;

        if (errors?.password || errors?.passwordConfirm) {
            if (password !== passwordConfirm) {
                return t(`registration.errors.passwordConfirm`);
            }

            if (errors?.passwordConfirm === 'minLength') {
                return t(`registration.validationRules.minLength`);
            }

            const errorMessage = errors?.password?.split(',');
            return t(`registration.validationRules.${errorMessage ? errorMessage[0] : 'charactersInARow'}`);
        }

        return undefined;
    };

    return (
        <Container fluid style={style}>
            <Row>
                <Col>
                    <Formik
                        onSubmit={onSubmit}
                        validationSchema={PROFILE_SCHEMA}
                        initialValues={{
                            email: undefined,
                            phoneNumber: undefined,
                            password: undefined,
                            passwordConfirm: undefined,
                            acceptedTos: undefined
                        }}
                    >
                        {({
                            values,
                            touched,
                            errors,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                            status
                        }) => (
                            <Form
                                id="profile-setup-form"
                                data-ga-form-name={formName}
                                onSubmit={handleSubmit}
                                autoComplete="off"
                            >
                                <Row>
                                    <Col>
                                        <div className="d-none">
                                            <Text
                                                name="autocomplete-buster-text"
                                                label={t('registration.email')}
                                                type="email"
                                            />
                                            <PasswordText
                                                name="autocomplete-buster-password"
                                                label={t('registration.password')}
                                                type="password"
                                            />
                                        </div>
                                        <Text
                                            name="email"
                                            label={t('registration.email')}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errors={
                                                errors?.email === 'invalid'
                                                    ? t(`registration.errors.email.invalid`)
                                                    : errors?.email === 'required'
                                                    ? t(`registration.errors.email.required`)
                                                    : undefined
                                            }
                                            touched={touched.email}
                                            maxLength={255}
                                            type="email"
                                            value={values?.email}
                                            footNote={<small>{t('registration.usernameNote')}</small>}
                                            onFocus={() => globalLink.handleFieldFocus(t('registration.email'))}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PhoneNumberText
                                            name="phoneNumber"
                                            label={t('registration.phoneNumber')}
                                            onChange={handleChange}
                                            errors={parsePhoneError(errors)}
                                            touched={touched.phoneNumber}
                                            value={values?.phoneNumber}
                                            defaultValue={values?.phoneNumber}
                                            countryCode={t(`countryCode`)}
                                            onFocus={() => globalLink.handleFieldFocus(t('registration.phoneNumber'))}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="password"
                                            label={t('registration.password')}
                                            type="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched.password}
                                            value={values?.password}
                                            errors={errors?.password}
                                            onFocus={() => globalLink.handleFieldFocus(t('registration.password'))}
                                        />
                                        <PasswordRules
                                            errors={errors?.password}
                                            touched={touched.password}
                                            password={values?.password}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <PasswordText
                                            name="passwordConfirm"
                                            label={t('registration.passwordConfirm')}
                                            type="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            errors={parsePwError(errors, values)}
                                            touched={touched.passwordConfirm || touched.password}
                                            value={values?.passwordConfirm}
                                            showErrorsText={true}
                                            autocomplete="new-password"
                                            onFocus={() =>
                                                globalLink.handleFieldFocus(t('registration.passwordConfirm'))
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text">
                                        <small>{t(`registration.optInNote`)}</small>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center">
                                        <Button
                                            async
                                            className="sm-full"
                                            label={t('button.submit')}
                                            disabled={isSubmitting}
                                            variant="primary"
                                            type="submit"
                                            dataGAFormName={formName}
                                            dataGAFormStep="Step2"
                                            dataGAFormStepName="Profile"
                                            isBusy={isSubmitting}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex flex-column align-items-center mt-3">
                                        <WorkflowSteps
                                            id="registration-page"
                                            activeStepName="Profile"
                                            activeStep={2}
                                            numberOfSteps={2}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
        </Container>
    );
};

export default ProfileSetupForm;
