import { AuthMobileNavProps } from './auth-mobile-nav.props';

// Hooks
import { useTranslation } from 'gatsby-plugin-react-i18next';

// UI Kit & Components
import { Row } from 'react-bootstrap';

import Button from 'ui-kit/button/button';

import './auth-mobile-nav.style.scss';

const AuthMobileNav = ({ onBackButtonClick, hideBackButton = false }: AuthMobileNavProps) => {
    const { t } = useTranslation();

    return (
        <Row className={`d-flex flex-row auth-mobile-nav-container justify-content-between`}>
            {!hideBackButton && (
                <Button
                    className="return-button"
                    type="button"
                    chevron="left"
                    variant="underline-blue-bold"
                    onClick={onBackButtonClick}
                    label={t('pages.profile.messages.labels.back')}
                />
            )}
        </Row>
    );
};

export default AuthMobileNav;
